export interface LoginResponse {
    token: string;
    expiration: Date;
    refreshToken: string;
    privacyConfirmed: boolean;
    role: string;
    companyName?: string;
    companyTruckLogo?: object;
    address: Array<object>;
    walletBalance?: number;
    totalReferralReward?: number;
    isCompanyTankInspectionEnabled?: boolean;
}

export interface CheckEmailInfo {
    email: string;
    isVaild: boolean;
}

export interface LoginInfo {
    isLoginDone: boolean;
    loginResponse: LoginResponse | null;
}
